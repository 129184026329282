import "./PageBecomePartner.css";
import Bold from "./styleBold";
import { useEffect, useRef, useState } from "react";

const BecomePartner = () => {
  const textRef = useRef(null);
  const imageRef = useRef(null);
  const [height, setHeight] = useState(0);

  const updateHeight = () => {
    if (textRef.current) {
      const newHeight = textRef.current.clientHeight;
      const getImageHeight = imageRef.current.clientHeight;
      setHeight(newHeight);
      console.log(newHeight, getImageHeight);
    }
  };

  useEffect(() => {
    updateHeight();

    // Add event listener for window resize
    window.addEventListener("resize", updateHeight);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  return (
    <div className="partner-page">
      <div className="partner-block">
        <div className="header">
          Partner with Bent Christy Graduate Assistance Program (BCGAP)
        </div>
        <div className="content">
          <div>
            By partnering with{" "}
            <Bold>Bent Christy Graduate Assistance Program (BCGAP)</Bold>, your
            brand will be aligned with a trusted nonprofit that is dedicated to
            eliminating the college loan crisis and creating a debt-free future
            for thousands of undergraduates and their families. Become a partner
            and help us drive the change needed for thousands of students
          </div>
          <div className="image-stats">
            <div className="image" style={{ maxHeight: height }} ref={imageRef}>
              <img src="./images/partners/partners7.jpg" alt="" />
            </div>
            <div className="text" ref={textRef}>
              <ul>
                <li>
                  <Bold>College Loan Debt Statistics: </Bold>99% of
                  undergraduate students and/or their parents owe over $100,000
                  in college loan debt. This debt is projected to increase
                  dramatically year after year.
                </li>
                <li>
                  <Bold>The Growing Crisis: </Bold> The college loan crisis has
                  become a significant financial challenge for millions of
                  individuals, surging to over $1.7 trillion. Studies show that
                  student loan debt is the second highest consumer debt after
                  mortgages, and it continues to grow annually.
                </li>
                <li>
                  <Bold>College Debt Free Future: </Bold>Our organization
                  envisions an educational landscape where students can pursue
                  higher education without the burden of college loan debt. We
                  aim to make college accessible and affordable for everyone,
                  ensuring that financial barriers do not hinder academic and
                  professional aspirations.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="partner-contact-us">
          <a href="/bent-christy-graduate-assistance-program-contact-us">
            <Bold>Contact us</Bold> &nbsp; to discuss partnership options!
            <img
              src="https://img.icons8.com/parakeet-line/27313a/96/new-post.png"
              alt="new-post"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default BecomePartner;

import "./bcgapCart.css";
import StoreTop from "./storeTop";
import { useState, useEffect } from "react";

const BcgapCart = () => {
  const [cart, setCart] = useState([]);
  const [totalPerItem, setTotalPerItem] = useState(0);

  useEffect(() => {
    setCart(JSON.parse(localStorage.getItem("cart")));
  }, []);

  const handleDeductQty = (index, quantity) => {
    if (quantity >= 2) {
      const newQuantity = quantity - 1;
      const newCart = [...cart];

      newCart[index] = {
        ...newCart[index],
        qty: newQuantity,
      };

      setCart(newCart);
      localStorage.setItem("cart", JSON.stringify(newCart));
    }
  };

  const handleAddQty = (index, quantity) => {
    const newQuantity = quantity + 1;
    const newCart = [...cart];

    newCart[index] = {
      ...newCart[index],
      qty: newQuantity,
    };

    setCart(newCart);
    localStorage.setItem("cart", JSON.stringify(newCart));
  };

  const calculateSubTotal = () => {
    return cart.reduce((total, item) => total + item.item.price * item.qty, 0);
  };

  return (
    <div className="cart-section">
      <StoreTop />

      <div className="cart">
        {cart &&
          cart.map((item, index) => (
            <div className="cart-item-card">
              <div className="cart-item-image">
                <img src={item.item.image} alt="card-item-image" />
              </div>
              <div className="cart-item-description">
                <div className="cart-item-title">{item.item.title}</div>
                <div className="cart-item-content">{item.item.description}</div>
                <div className="cart-item-price">
                  <span>Price:</span>
                  <span>${item.item.price}</span>
                </div>
                <div className="cart-item-quantity">
                  <span>Qty:</span>
                  <div
                    className="minus"
                    onClick={(e) => handleDeductQty(index, item.qty)}
                  >
                    &mdash;
                  </div>
                  <div className="qty-num">{item.qty}</div>
                  <div
                    className="plus"
                    onClick={(e) => handleAddQty(index, item.qty)}
                  >
                    {" "}
                    +{" "}
                  </div>
                </div>
                <div className="cart-item-total">
                  <span>Total:</span>
                  <span>${item.item.price * item.qty}</span>
                </div>
                <div className="cart-item-delete">
                  <img
                    src="https://img.icons8.com/ios-glyphs/90/df0202/filled-trash.png"
                    alt="filled-trash"
                  />
                </div>
              </div>
            </div>
          ))}
      </div>

      <div className="totalToPay">
        <div className="subtotal">
          <span>Subtotal:</span>
          <span> ${calculateSubTotal().toFixed(2)}</span>
        </div>
        <div className="shipping">
          <span>Shipping 20% (??):</span>
          <span>${(calculateSubTotal() * 0.2).toFixed(2)}</span>
        </div>

        <div className="tax">
          <span>Est. Tax 20% (??):</span>
          <span>${(calculateSubTotal() * 0.2).toFixed(2)}</span>
        </div>
        <div className="total">
          <span>Total:</span>
          <span>
            ${(calculateSubTotal() * 0.2 * 2 + calculateSubTotal()).toFixed(2)}
          </span>
        </div>
        <div className="checkout">
          <button className="checkout-btn">Checkout</button>
        </div>
      </div>
    </div>
  );
};

export default BcgapCart;

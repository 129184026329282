import "./PageForUndergrads-2.css";
import Bold from "./styleBold";
import Red from "./styleRed";
import Label from "./styleLabel";
import { useState, useRef } from "react";

const PageForUndergrads = () => {
  const applicationAboutRef = useRef(null);
  const applicationDeadlinesRef = useRef(null);
  const applicationFormRef = useRef(null);
  const [showContent, setShowContent] = useState([false, false, false]);

  const handleShowContent = (index) => {
    setShowContent((prevShowContent) =>
      prevShowContent.map((item, i) => (i === index ? !item : item))
    );
  };

  return (
    <div className="undergrad-page funding-page">
      <div className="funding-heading">
        Congratulations College Graduates! <br />
        What's Next?
      </div>
      <div className="funding-content">
        <div className="text-image">
          <div className="image">
            <video autoPlay muted loop>
              <source
                src="./video/undergrad/undergrad-mobile-video-06-04-v8.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="text">
            <div className="paragraph">
              Congratulations on your remarkable achievement of graduating from
              college!
            </div>
            <div className="paragraph">
              Your perseverance and hard work have opened up numerous
              opportunities, and your future should not be limited by student
              loan repayments. The excitement of graduation may have been
              dampened by the first loan payment. As you start your professional
              journey,{" "}
              <Bold>Bent Christy Graduate Assistance Program (BCGAP)</Bold> is
              here to ensure that those student loan repayments do not become an
              insurmountable obstacle. If you're struggling with college loan
              repayments, we are here to help ease the burden.
            </div>
            <div className="paragraph">
              Our <Bold>Repayment Program </Bold> is designed to alleviate the
              financial burden faced by many undergraduates and parents that are
              struggling to repay college loans.
            </div>{" "}
            <div className="paragraph">
              Our work is fueled by a passion for education, and being the
              change we need to see to end the college debt crisis. Learn more
              about our mission, our vision, and the opportunity how you can
              empower and help lighten the burden of college debt for you and
              the next generation.
            </div>
          </div>
        </div>
        <div
          style={{ marginTop: "2rem" }}
          className="subheading"
          ref={applicationAboutRef}
          onClick={() => handleShowContent(0)}
        >
          BCGAP Repayment Requirements{" "}
          <img
            style={{
              transform: showContent[0] ? "rotate(180deg)" : "rotate(0deg)",
            }}
            src="https://img.icons8.com/ios-filled/ff8332/100/expand-arrow--v1.png"
            alt="show-about-scholarship"
          />
        </div>
        <div
          className="application-content"
          style={{ display: showContent[0] ? "block" : "none" }}
        >
          <div>
            If you are an undergraduate struggling to keep up with your college
            loan payments, even while working full-time and budgeting carefully,
            our organization is here to help alleviate your financial burden.
            You are encouraged to apply for loan repayment assistance if you
            meet the following criteria:
          </div>
          <div>
            <ul>
              <li>Attended a 4-year college</li>
              <li>
                {" "}
                Completed your degree in 4 years <Red>*</Red>
              </li>
              <li>Experiencing hardship due to student debt repayments</li>
            </ul>
            <div style={{ marginTop: ".5rem" }}></div>
            <Red> * </Red> If personal hardships (such as caring for an ill
            parent, financial need, etc.) delayed your graduation, you are still
            eligible to apply. Please detail your hardship in your application
            materials.
          </div>
        </div>
        <div
          className="subheading"
          ref={applicationDeadlinesRef}
          onClick={() => handleShowContent(1)}
        >
          BCGAP Repayment Documents
          <img
            style={{
              transform: showContent[1] ? "rotate(180deg)" : "rotate(0deg)",
            }}
            src="https://img.icons8.com/ios-filled/ff8332/100/expand-arrow--v1.png"
            alt="show-application-deadline"
          />
        </div>
        <div
          className="application-content"
          style={{ display: showContent[1] ? "block" : "none" }}
        >
          To apply for the <Bold>BCGAP Undergraduate Repayment</Bold>, please be
          prepared to submit the following documents:
          <ol>
            <li>Official College Transcript</li>
            <li>Loan Agreement or Promissory Note</li>
            <li>Loan Servicer Statements</li>
            <li>Provide current income and expense </li>
            <li>
              Letter from your employer with Employment status: Full-time.
              Part-time, Laid-off
            </li>
            <li>Current Income Tax and employee pay stub </li>
            <li>Provide governance assistance if laid-off </li>
            <li>Loan repayment history record </li>
            <li>
              Credit Report showing the status of the student loans, and how
              loan repayment is affecting credit score{" "}
            </li>
            <li>Letter from college showing your start and end date </li>
            <li>Application Letter (min. 500 words)</li>
            <ul>
              <div style={{ marginLeft: "-2rem", marginTop: ".5rem" }}>
                <b>In your letter, please discuss:</b>{" "}
              </div>
              <li>What motivated you to pursue a college degree?</li>
              <li>
                Did you complete your degree within four years? If you
                encountered any challenges that delayed your graduation, please
                share the details.
              </li>{" "}
              <li>How did you finance your college education?</li>
              <li>
                What sacrifices did you and/or your family have to make to cover
                the cost of your college degree?
              </li>
              <li>
                If selected, how would the{" "}
                <Bold>
                  Bent Christy Graduate Assistance Program (BCGAP) Repayment
                  Program
                </Bold>{" "}
                assist you in achieving your personal and/or professional goals?
              </li>
              <li>
                Did you engage in any community service or volunteer activities
                during college? If so, please describe what you did and for
                which organization. If not, please explain why.
              </li>
            </ul>
          </ol>
        </div>
        <div
          className="subheading"
          ref={applicationFormRef}
          onClick={() => handleShowContent(2)}
        >
          BCGAP Repayment Application
          <img
            style={{
              transform: showContent[2] ? "rotate(180deg)" : "rotate(0deg)",
            }}
            src="https://img.icons8.com/ios-filled/ff8332/100/expand-arrow--v1.png"
            alt="show-application-form"
          />
        </div>
        <div
          className="application-content"
          style={{ display: showContent[2] ? "block" : "none" }}
        >
          Please fill out the form below to submit an application for the{" "}
          <Bold>BCGAP Undergraduate Repayment</Bold>:
          <form>
            <Label>Student Basic Info:</Label>
            <label htmlFor="first-name">
              First Name <Red>*</Red>
            </label>
            <input type="text" id="first-name" name="firstName" required />
            <label htmlFor="mid-initial">Middle Initial</label>
            <input type="text" id="mid-initial" name="midInitial" />
            <label htmlFor="last-name">
              Last Name <Red>*</Red>
            </label>
            <input type="text" id="last-name" name="lastName" required />
            <label htmlFor="suffix">
              Suffix <Red>*</Red>
            </label>
            <input type="text" id="suffix" name="suffix" required />
            <label htmlFor="email">
              Email <Red>*</Red>
            </label>
            <input type="email" id="email" name="email" required />
            <Label>Student Contact Info:</Label>
            <label htmlFor="phone">
              Phone <Red>*</Red>
            </label>
            <input type="text" id="phone" name="phone" required />
            <label htmlFor="permanent-address">
              Permanent Address <Red>*</Red>
            </label>
            <input
              type="text"
              id="permanent-address"
              name="permanentAddress"
              required
            />
            <label htmlFor="address-2">Address 2 </label>
            <input type="text" id="address-2" name="address2" />
            <label htmlFor="city">
              City <Red>*</Red>{" "}
            </label>
            <input type="text" id="city" name="city" required />
            <label htmlFor="state">
              State <Red>*</Red>
            </label>
            <input type="text" id="state" name="state" required />
            <label htmlFor="zip">
              Zip Code <Red>*</Red>
            </label>
            <input type="text" id="zip" name="zip" required />

            <Label>Student Personal Info:</Label>
            <label htmlFor="dob">
              Date of Birth (MM/DD/YYYY) <Red>*</Red>
            </label>
            <input type="text" id="dob" name="dob" required />
            <label htmlFor="citizenship">
              Citizenship <Red> * </Red>{" "}
            </label>
            <input type="text" id="citizenship" name="citizenship" required />
            <label htmlFor="ssn">
              Social Security Number <Red> * </Red>
            </label>
            <input type="text" id="ssn" name="ssn" required />
            <label htmlFor="confirm-ssn">
              Confirm Social Security Number <Red> * </Red>
            </label>
            <input type="text" id="confirm-ssn" name="confirm-ssn" required />

            <Label>School and Loan Info:</Label>
            <label htmlFor="college-attended">
              College Attended <Red>*</Red>
            </label>
            <input
              type="text"
              id="college-attended"
              name="collegeAttended"
              required
            />
            <label htmlFor="loan-amount">
              College Loan Amount <Red>*</Red>
            </label>
            <input type="text" id="loan-amount" name="loanAmount" required />
            <label htmlFor="loan-year">
              Year in School Loan Will Help Cover <Red>*</Red>
            </label>
            <input type="text" id="loan-year" name="loanYear" required />
            <label htmlFor="major-in-college">
              College Major (and Minor, if applicable) <Red>*</Red>
            </label>
            <input
              type="text"
              id="major-in-college"
              name="majorInCollege"
              required
            />
            <label htmlFor="year-enrolled">
              Year Enrolled <Red>*</Red>
            </label>
            <input
              type="text"
              id="year-enrolled"
              name="yearEnrolled"
              required
            />
            <label htmlFor="year-completed">
              Year Completed <Red>*</Red>
            </label>
            <input
              type="text"
              id="year-completed"
              name="yearCompleted"
              required
            />
            <label htmlFor="college-transcript">
              Transcript <Red>*</Red>
            </label>
            <input
              type="file"
              id="college-transcript"
              name="collegeTranscript"
              required
            />

            <label htmlFor="loan-agreement">
              Loan Agreement or Promissory Note <Red>*</Red>
            </label>
            <input
              type="file"
              id="loan-agreement"
              name="loanAgreement"
              required
            />

            <label htmlFor="loan-statement">
              Loan Servicer Statements <Red>*</Red>
            </label>
            <input
              type="file"
              id="loan-statement"
              name="loanStatement"
              required
            />

            <label htmlFor="application-letter">
              Application Letter (See the instructions above) <Red>*</Red>
            </label>
            <input
              type="file"
              id="application-letter"
              name="applicationLetter"
              required
            />

            <Label>Proof of Financial Need Info: </Label>
            <label htmlFor="current-expense">
              Current income and expense documentation <Red>*</Red>
            </label>
            <input
              type="file"
              id="current-expense"
              name="currentExpense"
              required
            />
            <label htmlFor="exployment-status">
              Employment status: Full-time, Part-time, or Laid-off <Red>*</Red>
            </label>
            <input
              type="text"
              id="exployment-status"
              name="exploymentStatus"
              required
            />

            <label htmlFor="gross-income">
              Gross Annual Income <Red>*</Red>
            </label>
            <input type="text" id="gross-income" name="grossIncome" required />

            <label htmlFor="loan-repayment-history">
              Loan repayment history record <Red>*</Red>
            </label>
            <input
              type="file"
              id="loan-repayment-history"
              name="loanRepaymentHistory"
              required
            />

            <label htmlFor="credit-report">
              Credit Report showing the status of the student loans, and how
              loan repayment is affecting credit score <Red>*</Red>
            </label>
            <input
              type="file"
              id="credit-report"
              name="creditReport"
              required
            />

            <label htmlFor="household-size">
              Household size <Red>*</Red>
            </label>
            <input
              type="text"
              id="household-size"
              name="householdSize"
              required
            />

            <label htmlFor="house-status">
              House Own / Rent <Red>*</Red>
            </label>
            <input type="text" id="house-status" name="houseStatus" required />

            <input type="submit" value="Submit" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default PageForUndergrads;

import "./contactUs.css";
import Red from "./styleRed";
import Bold from "./styleBold";

const ContactUs = () => {
  return (
    <div className="contact-us-page">
      <div className="contact-us-heading">Contact Us</div>
      <div className="contact-us-section">
        <div className="images-header-all">
          <div className="image-group">
            <div className="image-header">
              <img src="./images/contact/contact4.png" alt="" />
            </div>
            <div className="image-header">
              <img src="./images/contact/contact8.png" alt="" />
            </div>
            <div className="image-header">
              <img src="./images/contact/contact1.png" alt="" />
            </div>
            <div className="image-header">
              <img src="./images/contact/contact3.png" alt="" />
            </div>
          </div>
        </div>
        <div className="contact-details">
          <div className="icon">
            <img
              src="https://img.icons8.com/parakeet-line/96/new-post.png"
              alt="new-post"
            />
            <Bold>Email: </Bold> email@example.com
          </div>
          <div className="icon">
            <img
              src="https://img.icons8.com/ios/100/phone-message.png"
              alt="phone-message"
            />
            <Bold> Phone: </Bold> (414) 123-1516
          </div>
        </div>

        <div className="contact-form">
          <form>
            <label htmlFor="full-name">
              Full Name <Red> * </Red>
            </label>
            <input type="text" name="fullName" required />
            <label htmlFor="email">
              Email <Red> * </Red>
            </label>
            <input type="email" name="email" required />
            <label htmlFor="message">
              Message <Red> * </Red>
            </label>
            <textarea name="message" rows="5" required />
            <input type="submit" value="Send" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;

import "./PageDonate.css";
import { useState, useEffect } from "react";
import Bold from "./styleBold";
import Larger from "./styleLargerFont";

const PageDonate = () => {
  const donateImages = [
    "./images/donate/donation3.webp",
    "./images/donate/donation4.webp",
    "./images/donate/donation2.webp",
    "./images/donate/donation7.webp",
    "./images/donate/donation8.webp",
    "./images/donate/donation9.webp",
  ];
  const [currentImage, setCurrentImage] = useState(0);
  const [move, setMove] = useState(-100);
  const [showAd, setShowAd] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % donateImages.length);
    }, 2000);

    return () => clearInterval(interval);
  }, [donateImages.length]);

  const visibleImages = [];
  for (let i = 0; i < 7; i++) {
    visibleImages.push(donateImages[(currentImage + i) % donateImages.length]);
  }

  const visibleImages2 = [];
  for (let i = 0; i < 7; i++) {
    visibleImages2.push(donateImages[i]);
  }

  useEffect(() => {
    const intervalMove = setInterval(() => {
      if (move <= -2500) {
        setMove(1000);
      } else {
        setMove((prevMove) => prevMove - 1);
      }
    }, 14);

    return () => clearInterval(intervalMove);
  }, [move]);

  useEffect(() => {
    setShowAd(true);

    console.log(showAd);
  }, []);

  const handleClose = () => {
    setShowAd(false);
  };

  const handleNavToDonate = () => {
    handleClose();
    window.location.hash = "#scroll-to-donate";
  };

  return (
    <>
      <div className={`overlay-ad ${showAd ? "visible" : ""}`}>
        <div className="tshirt-ad">
          <div className="bg-3">
            {" "}
            <div className="image">
              <img src="./images/donate/shirt-ad.png" alt="" />
            </div>
            <div className="content">
              <div>Donate $100 to get a FREE t-shirt!</div>
            </div>
          </div>
          <div className="close" onClick={handleClose}>
            <img
              src="https://img.icons8.com/ios/ffffff/100/cancel.png"
              alt="cancel"
            />
          </div>
          <button onClick={handleNavToDonate}>Donate Now</button>
        </div>
      </div>
      <div className="donate-section">
        <div class="loop">
          <div className="image-container-one">
            {visibleImages2 &&
              visibleImages2.map((image, index) => (
                <img key={index} src={image} alt="" />
              ))}
          </div>
          <div className="image-container-two">
            {visibleImages2 &&
              visibleImages2.map((image, index) => (
                <img key={index} src={image} alt="" />
              ))}
          </div>
        </div>
        <div className="donate-heading">Donate now to end college debt!</div>
        <div className="donate-subheading">
          Our program believes that we can all be the change we want to see.
          Education is a cornerstone of personal and professional growth, yet
          many students face the daunting challenge of repaying their college
          loans. Your generosity can make a world of difference.{" "}
          <span className="thank-you">Thank you for your support!</span>
        </div>
        <div className="donate-options" id="scroll-to-donate">
          <div className="donate-option">
            <img
              src="https://img.icons8.com/external-thin-kawalan-studio/ff3700/96/external-donate-user-interface-thin-kawalan-studio.png"
              alt="external-donate-user-interface-thin-kawalan-studio"
            />
            <div className="donate-section-header">$1 Fundraising</div>
            <div className="donate-section-text">
              Our organization believes in collective change. By contributing
              $1, you can help make higher education more accessible and
              affordable. Every dollar raised goes directly towards helping
              individuals repay college loans. Join us in this movement, showing
              that unity and small contributions can create significant change.
              {/**/}
            </div>
            <div className="donate-btn">
              <a
                href="https://www.sandbox.paypal.com/donate/?hosted_button_id=6G9BTCSPE8B98"
                target="_blank"
              >
                Donate
              </a>
            </div>
          </div>
          <div className="donate-option">
            <img
              src="https://img.icons8.com/ios/fac800/100/hand-holding-heart.png"
              alt="hand-holding-heart"
            />
            <div className="donate-section-header">One-Time Donation</div>
            <div className="donate-section-text">
              By contributing a one-time donation to assist with student loan
              repayment, you can make a lasting impact on a graduate’s future.
              Your support alleviates loan pressure, allowing students to focus
              on personal and professional growth. Every contribution, no matter
              the size, makes a meaningful difference.
            </div>
            <div className="donate-btn">
              <a
                href="https://www.sandbox.paypal.com/donate/?hosted_button_id=6G9BTCSPE8B98"
                target="_blank"
              >
                Donate{" "}
              </a>
            </div>
          </div>
          <div className="donate-option">
            <img
              src="https://img.icons8.com/ios/00c8dc/100/handshake-heart.png"
              alt="handshake-heart"
            />
            <div className="donate-section-header">Monthly Support </div>
            <div className="donate-section-text">
              Education is fundamental to growth, yet many students struggle
              with repaying college loans. Join our monthly support program to
              make a tangible difference. Your support provides crucial
              financial assistance, easing the burden of student loans.
              Together, we can pave the way for a brighter future.
            </div>

            <div className="donate-btn">
              <a
                href="https://www.sandbox.paypal.com/donate/?hosted_button_id=6G9BTCSPE8B98"
                target="_blank"
              >
                Donate{" "}
              </a>
            </div>
          </div>
        </div>
        <div className="donate-banner-partner">
          <div className="image">
            <img
              src="./images/donate/donate-corporate.jpg"
              alt="bcgap-banner-become-partner"
            />
          </div>
          <div className="text">
            <div className="donate-banner-header">
              <Bold>Partner with BCGAP</Bold>
            </div>
            <div className="donate-banner-body">
              By partnering with Bent Christy Graduate Assistance Program
              (BCGAP), your brand will be aligned with a trusted nonprofit that
              is dedicated to eliminating the college loan crisis and creating a
              debt-free future for thousands of undergraduates and their
              families ...
            </div>
            <div className="learn-more">
              <a href="/bent-christy-graduate-assistance-program-become-a-partner">
                Learn more{" "}
                <img
                  src="https://img.icons8.com/pastel-glyph/64/ffffff/circled-right.png"
                  alt="learn-more-arrow"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="corporate-section-heading">Other Ways to Give</div>
        <div className="donate-stocks-subheading">
          To maximize the impact of your contribution, consider exploring
          options such as employer gift matching, stock gifts, or planned
          giving. Many companies offer matching gift programs, where they match
          charitable donations made by their employees, effectively doubling—or
          even tripling—the impact of your gift. To ensure your donation
          qualifies, contact your company’s human resources or corporate social
          responsibility department for specific instructions and guidelines.
          Similarly, if you are interested in donating stock, reach out to your
          financial advisor for guidance on how to transfer appreciated
          securities directly to the non-profit, which can be a tax-efficient
          way to give. For those considering planned giving, consulting with an
          estate planning advisor can help you understand the various options
          available, such as including the non-profit in your will, creating a
          charitable trust, or designating the organization as a beneficiary of
          retirement accounts or life insurance policies. These advisors can
          provide specific instructions to ensure that your gift is structured
          in a way that aligns with your financial goals while maximizing its
          benefit to the non-profit.
        </div>

        <div className="donate-options-corporate-wrapper">
          <div className="donate-option-corporate">
            <div className="donate-option-corporate-image">
              <img
                src="https://img.icons8.com/external-kmg-design-glyph-kmg-design/64/external-bank-finance-2-kmg-design-glyph-kmg-design.png"
                alt="external-bank-finance-2-kmg-design-glyph-kmg-design"
              />
            </div>
            <div className="donate-option-corporate-header">
              Employer Gift Match
            </div>
            <div className="donate-option-corporate-content">
              Did you know that many employers offer corporate gift matching,
              which can double or even triple your charitable contributions?
              This extra support can make a meaningful difference for
              undergraduates and parents dealing with the burden of college loan
              repayment.
            </div>
          </div>
          <div className="donate-option-corporate">
            <div className="donate-option-corporate-image">
              <img
                src="https://img.icons8.com/sf-regular-filled/96/1A1A1A/positive-dynamic.png"
                alt="positive-dynamic"
              />
            </div>
            <div className="donate-option-corporate-header">Stock Gift</div>
            <div className="donate-option-corporate-content">
              Donating securities to support undergraduates and parents facing
              the challenges of college loan repayment provides a unique
              opportunity for philanthropy. This special form of giving can help
              create a brighter future for countless college graduates and their
              families for years to come.
            </div>
          </div>
          <div className="donate-option-corporate">
            <div className="donate-option-corporate-image">
              <img
                src="https://img.icons8.com/glyph-neue/64/bonds.png"
                alt="bonds"
              />
            </div>
            <div className="donate-option-corporate-header">Planned Giving</div>
            <div className="donate-option-corporate-content">
              Please consider including Bent Christy Graduate Assistance Program
              (BCGAP) in your estate planning. Your contribution is crucial for
              the long-term sustainability of our program and can help secure
              funds for thousands of undergraduates and parents facing the
              challenges of college loan repayment.
            </div>
          </div>
        </div>
        <div className="donate-contact-us">
          <a href="/bent-christy-graduate-assistance-program-contact-us">
            <span>Have questions? </span> <span> Contact us!</span>{" "}
            <span>
              {" "}
              <img
                src="https://img.icons8.com/parakeet-line/27313a/96/new-post.png"
                alt="new-post"
              />
            </span>
          </a>
        </div>
      </div>
    </>
  );
};

export default PageDonate;

import { useState, useEffect } from "react";
import Bold from "./styleBold";

const DonateStocks = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [move, setMove] = useState(-100);
  const donateImages = [
    "./images/donate/donate-stocks3.jpg",
    "./images/donate/donate-stocks8.jpg",
    "./images/donate/donate-corporate.jpg",
    "./images/donate/donate-stocks2.jpg",
    "./images/donate/donate-stocks7.jpg",
    "./images/donate/donate-stocks1.jpg",
    "./images/donate/donate-stocks4.jpg",
  ];
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % donateImages.length);
    }, 2000);

    return () => clearInterval(interval);
  }, [donateImages.length]);

  const visibleImages = [];
  for (let i = 0; i < 7; i++) {
    visibleImages.push(donateImages[(currentImage + i) % donateImages.length]);
  }

  const visibleImages2 = [];
  for (let i = 0; i < 7; i++) {
    visibleImages2.push(donateImages[i]);
  }

  useEffect(() => {
    const intervalMove = setInterval(() => {
      if (move <= -2500) {
        setMove(1000);
      } else {
        setMove((prevMove) => prevMove - 1);
      }
    }, 14);

    return () => clearInterval(intervalMove);
  }, [move]);

  return (
    <div className="donate-stocks-page">
      <div>
        <div class="loop" id="donate-scroll-to">
          <div className="image-container-one">
            {visibleImages2 &&
              visibleImages2.map((image, index) => (
                <img key={index} src={image} alt="" />
              ))}
          </div>
          <div className="image-container-two">
            {visibleImages2 &&
              visibleImages2.map((image, index) => (
                <img key={index} src={image} alt="" />
              ))}
          </div>
        </div>
      </div>
      <div className="corporate-section-heading">Other Ways to Contribute</div>
      <div className="donate-stocks-subheading">
        Contact your company or your financial advisor or your estate planning
        advisor to get specific instructions on how your gift can qualify for
        your company's matching gift program
      </div>
      <div className="donate-options-corporate-wrapper">
        <div className="donate-option-corporate">
          <div className="donate-option-corporate-image">
            <img
              src="https://img.icons8.com/external-kmg-design-glyph-kmg-design/64/external-bank-finance-2-kmg-design-glyph-kmg-design.png"
              alt="external-bank-finance-2-kmg-design-glyph-kmg-design"
            />
          </div>
          <div className="donate-option-corporate-header">
            Employer Gift Match
          </div>
          <div className="donate-option-corporate-content">
            Did you know that many employers offer corporate gift matching,
            which can double or even triple your charitable contributions? This
            extra support can make a meaningful difference for undergraduates
            and parents dealing with the burden of college loan repayment.
          </div>
        </div>
        <div className="donate-option-corporate">
          <div className="donate-option-corporate-image">
            <img
              src="https://img.icons8.com/sf-regular-filled/96/1A1A1A/positive-dynamic.png"
              alt="positive-dynamic"
            />
          </div>
          <div className="donate-option-corporate-header">Stock Gift</div>
          <div className="donate-option-corporate-content">
            Donating securities to support undergraduates and parents facing the
            challenges of college loan repayment provides a unique opportunity
            for philanthropy. This special form of giving can help create a
            brighter future for countless college graduates and their families
            for years to come.
          </div>
        </div>
        <div className="donate-option-corporate">
          <div className="donate-option-corporate-image">
            <img
              src="https://img.icons8.com/glyph-neue/64/bonds.png"
              alt="bonds"
            />
          </div>
          <div className="donate-option-corporate-header">Planned Giving</div>
          <div className="donate-option-corporate-content">
            Please consider including Bent Christy Graduate Assistance Program
            (BCGAP) in your estate planning. Your contribution is crucial for
            the long-term sustainability of our program and can help secure
            funds for thousands of undergraduates and parents facing the
            challenges of college loan repayment.
          </div>
        </div>
      </div>
      <div className="donate-contact-us">
        <a href="/bent-christy-graduate-assistance-program-contact-us">
          <span>Have questions? </span> <span> Contact us!</span>{" "}
          <span>
            {" "}
            <img
              src="https://img.icons8.com/parakeet-line/27313a/96/new-post.png"
              alt="new-post"
            />
          </span>
        </a>
      </div>
    </div>
  );
};

export default DonateStocks;

import "./PageNewsStories.css";

const PageNewsStories = () => {
  return (
    <div className="news-page">
      <div className="news-section">
        <div className="news-card">
          <div className="image">
            <img src="./images/news/news1.jpg" alt="bcgap-news-photo" />
          </div>
          <div className="content">
            <div className="title">
              Learn More About Student Loan Debt Relief
            </div>
            <div className="text">
              Federal student loans offer flexible repayment plans, loan
              consolidation, forgiveness programs, and more. We can help you
              manage repayment and answer any questions you have along the way
              ...
            </div>
            <div className="read-more-link">
              <a href="https://studentaid.gov">Read more</a>{" "}
            </div>
          </div>
        </div>
        <div className="news-card">
          <div className="image">
            <img src="./images/news/news6.jpg" alt="bcgap-news-photo" />
          </div>
          <div className="content">
            <div className="title">
              FACT SHEET: President Biden Cancels Student Debt for more than
              150,000 Student Loan Borrowers Ahead of Schedule
            </div>
            <div className="text">
              Today, President Biden announced the approval of $1.2 billion in
              student debt cancellation for almost 153,000 borrowers currently
              enrolled in the Saving on a Valuable Education (SAVE) repayment
              plan ...
            </div>
            <div className="read-more-link">
              <a href="https://www.whitehouse.gov/briefing-room/statements-releases/2024/02/21/fact-sheet-president-biden-cancels-student-debt-for-more-than-150000-student-loan-borrowers-ahead-of-schedule/">
                Read more
              </a>{" "}
            </div>
          </div>
        </div>
        <div className="news-card">
          <div className="image">
            <img src="./images/news/news4.jpg" alt="bcgap-news-photo" />
          </div>
          <div className="content">
            <div className="title">
              Student Loan Forgiveness (and Other Ways the Government Can Help
              You Repay Your Loans)
            </div>
            <div className="text">
              The Supreme Court blocked the one-time debt relief plan (you may
              also know this as the forgiveness of up to $20,000 for Pell Grant
              borrowers). But you may be able to get help repaying your loans,
              including full loan forgiveness, through other federal student
              loan ...
            </div>
            <div className="read-more-link">
              <a href="https://studentaid.gov/articles/student-loan-forgiveness/">
                Read more
              </a>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNewsStories;

import "./PageForParents-2.css";
import Bold from "./styleBold";
import Red from "./styleRed";
import Label from "./styleLabel";
import { useState, useRef } from "react";

const PageForParents = () => {
  const applicationAboutRef = useRef(null);
  const applicationDeadlinesRef = useRef(null);
  const applicationFormRef = useRef(null);
  const [showContent, setShowContent] = useState([false, false, false]);

  const handleShowContent = (index) => {
    setShowContent((prevShowContent) =>
      prevShowContent.map((item, i) => (i === index ? !item : item))
    );
  };

  return (
    <div className="parents-page funding-page">
      <div className="funding-heading">
        Bent Christy Graduate Assistance Program (BCGAP) Parent Repayment
      </div>
      <div className="funding-content">
        <div className="text-image">
          <div className="image">
            <img
              style={{ objectPosition: "0 38%" }}
              src="./images/parents/parents2.webp"
              alt="parents-photo"
            />
          </div>
          <div className="text">
            <div className="paragraph">
              As dedicated parents, the burden of college loans can be
              overwhelming, sometimes forcing you to refinance homes, use
              retirement savings, or delay retirement to repay your children's
              college loans.
              <div style={{ padding: "0 0 .5rem 0" }}></div>
              <Bold>Bent Christy Graduate Assistance Program (BCGAP)</Bold>{" "}
              understands the challenges and stress of managing these
              repayments. If you're struggling with college loan repayments, we
              are here to help ease the burden. Contact us with any questions
              you may have about our program and how we can assist with your
              children's loan repayment. Our organization understands that
              managing student loan repayment can be challenging. If you're
              struggling with your college loan repayments, we are here to help.
              <div style={{ padding: "0 0 .5rem 0" }}></div>
              Our <Bold>Parent Repayment Program</Bold> is designed to alleviate
              the financial burden faced by many undergraduates and parents that
              are struggling to repay college loans.
            </div>{" "}
          </div>
        </div>
        <div
          style={{ marginTop: "2rem" }}
          className="subheading"
          ref={applicationAboutRef}
          onClick={() => handleShowContent(0)}
        >
          BCGAP Repayment Requirements{" "}
          <img
            style={{
              transform: showContent[0] ? "rotate(180deg)" : "rotate(0deg)",
            }}
            src="https://img.icons8.com/ios-filled/ff8332/100/expand-arrow--v1.png"
            alt="show-about-scholarship"
          />
        </div>
        <div
          className="application-content"
          style={{ display: showContent[0] ? "block" : "none" }}
        >
          <div>
            If you are a parent struggling to keep up with your child&#39;s
            college loan repayments, you are not alone. Our organization is here
            to help alleviate this financial burden. You are encouraged to apply
            for loan repayment assistance if your child meets the following
            criteria:
          </div>
          <div>
            <ul>
              <li>Attended a 4-year college</li>
              <li>
                {" "}
                Completed your degree in 4 years <Red>*</Red>
              </li>
              <li>Experiencing hardship due to student debt repayments</li>
            </ul>
            <div style={{ marginTop: ".5rem" }}></div>
            <Red> * </Red> If personal hardships (such as caring for an ill
            parent, financial need, etc.) delayed your child's graduation, you
            are still eligible to apply. Please detail your child's hardship in
            your application materials.
          </div>
        </div>
        <div
          className="subheading"
          ref={applicationDeadlinesRef}
          onClick={() => handleShowContent(1)}
        >
          BCGAP Repayment Documents
          <img
            style={{
              transform: showContent[1] ? "rotate(180deg)" : "rotate(0deg)",
            }}
            src="https://img.icons8.com/ios-filled/ff8332/100/expand-arrow--v1.png"
            alt="show-application-deadline"
          />
        </div>
        <div
          className="application-content"
          style={{ display: showContent[1] ? "block" : "none" }}
        >
          To apply for the BCGAP Parent Repayment, please be prepared to submit
          the following documents:
          <ol>
            <li>The Child's Official College Transcript</li>
            <li>Loan Agreement or Promissory Note</li>
            <li>Loan Servicer Statements</li>
            <li>Provide current income and expense </li>
            <li>
              Letter from your employer with Employment status: Full-time.
              Part-time, Laid-off
            </li>
            <li>Current Income Tax and employee pay stub </li>
            <li>Provide governance assistance if laid-off </li>
            <li>Loan repayment history record </li>
            <li>
              Credit Report showing the status of the student loans, and how
              loan repayment is affecting credit score{" "}
            </li>
            <li>
              Letter from your child's college showing his/her start and end
              date{" "}
            </li>
            <li>Application Letter (min. 500 words)</li>
            <ul>
              <div style={{ marginLeft: "-2rem", marginTop: ".5rem" }}>
                <b>In your letter, please discuss:</b>{" "}
              </div>
              <li>
                How does the loan repayment impact your current budget and
                overall financial stability?
              </li>
              <li>
                What sacrifices did you and/or your family have to make to cover
                the cost of your child college degree?
              </li>{" "}
              <li>
                How are you balancing loan repayment with other financial
                obligations, such as saving for retirement or covering daily
                expenses?
              </li>
              <li>
                How is the stress of repaying these loans affecting your
                emotional well-being and family relationships?
              </li>
              <li>
                If selected, how would the{" "}
                <Bold>
                  {" "}
                  Bent Christy Graduate Assistance Program (BCGAP) Repayment
                  Program
                </Bold>{" "}
                assist you in achieving your personal and/or professional goals?
              </li>
            </ul>
          </ol>
        </div>
        <div
          className="subheading"
          ref={applicationFormRef}
          onClick={() => handleShowContent(2)}
        >
          BCGAP Repayment Application
          <img
            style={{
              transform: showContent[2] ? "rotate(180deg)" : "rotate(0deg)",
            }}
            src="https://img.icons8.com/ios-filled/ff8332/100/expand-arrow--v1.png"
            alt="show-application-form"
          />
        </div>
        <div
          className="application-content"
          style={{ display: showContent[2] ? "block" : "none" }}
        >
          Please fill out the form below to submit an application for the{" "}
          <Bold>BCGAP Parent Repayment</Bold>:
          <form>
            <Label>Parent Basic Info:</Label>
            <label htmlFor="parent-first-name">
              First Name <Red>*</Red>
            </label>
            <input
              type="text"
              id="parent-first-name"
              name="parentFirstName"
              required
            />
            <label htmlFor="parent-mid-initial">Middle Initial</label>
            <input
              type="text"
              id="parent-mid-initial"
              name="parentMidInitial"
            />
            <label htmlFor="parent-last-name">
              Last Name <Red>*</Red>
            </label>
            <input
              type="text"
              id="parent-last-name"
              name="parentLastName"
              required
            />
            <label htmlFor="parent-suffix">
              Suffix <Red>*</Red>
            </label>
            <input
              type="text"
              id="parent-suffix"
              name="parentSuffix"
              required
            />

            <Label>Parent Contact Info:</Label>
            <label htmlFor="parent-phone">
              Phone <Red>*</Red>
            </label>
            <input type="text" id="parent-phone" name="parent-phone" required />
            <label htmlFor="parent-email">
              Email <Red>*</Red>
            </label>
            <input
              type="email"
              id="parent-email"
              name="parent-email"
              required
            />
            <label htmlFor="parent-permanent-address">
              Permanent Address <Red>*</Red>
            </label>
            <input
              type="text"
              id="parent-permanent-address"
              name="parentPermanentAddress"
              required
            />
            <label htmlFor="parent-address-2">Address 2 </label>
            <input type="text" id="parent-address-2" name="parentAddress2" />
            <label htmlFor="parent-city">
              City <Red>*</Red>{" "}
            </label>
            <input type="text" id="parent-city" name="parentCity" required />
            <label htmlFor="parent-state">
              State <Red>*</Red>
            </label>
            <input type="text" id="parent-state" name="parentState" required />
            <label htmlFor="parent-zip">
              Zip Code <Red>*</Red>
            </label>
            <input type="text" id="parent-zip" name="parentZip" required />

            <Label>Parent Personal Info:</Label>
            <label htmlFor="parent-dob">
              Date of Birth (MM/DD/YYYY) <Red>*</Red>
            </label>
            <input type="text" id="parent-dob" name="parentDob" required />
            <label htmlFor="parent-citizenship">
              Citizenship <Red> * </Red>{" "}
            </label>
            <input
              type="text"
              id="parent-citizenship"
              name="parentCitizenship"
              required
            />
            <label htmlFor="parent-ssn">
              Social Security Number <Red> * </Red>
            </label>
            <input type="text" id="parent-ssn" name="parentSsn" required />
            <label htmlFor="parent-confirm-ssn">
              Confirm Social Security Number <Red> * </Red>
            </label>
            <input
              type="text"
              id="parent-confirm-ssn"
              name="parentConfirmSsn"
              required
            />

            <Label>Student Info:</Label>
            <label htmlFor="student-first-name">
              First Name <Red>*</Red>
            </label>
            <input
              type="text"
              id="student-first-name"
              name="studentFirstName"
              required
            />
            <label htmlFor="student-mid-initial">Middle Initial</label>
            <input
              type="text"
              id="student-mid-initial"
              name="studentMidInitial"
            />
            <label htmlFor="student-last-name">
              Last Name <Red>*</Red>
            </label>
            <input
              type="text"
              id="student-last-name"
              name="studentLastName"
              required
            />
            <label htmlFor="student-dob">
              Date of Birth (MM/DD/YYYY) <Red>*</Red>
            </label>
            <input type="text" id="student-dob" name="studentDob" required />
            <label htmlFor="student-citizenship">
              Citizenship <Red> * </Red>{" "}
            </label>
            <input
              type="text"
              id="student-citizenship"
              name="studentCitizenship"
              required
            />
            <label htmlFor="studentSsn">
              Social Security Number <Red> * </Red>
            </label>
            <input type="text" id="student-ssn" name="studentSsn" required />
            <label htmlFor="student-confirm-ssn">
              Confirm Social Security Number <Red> * </Red>
            </label>
            <input
              type="text"
              id="student-confirm-ssn"
              name="studnetConfirmSsn"
              required
            />

            <label htmlFor="student-address">
              Address if not the same as parent{" "}
            </label>
            <input type="text" id="studentt-address" name="studentAddress" />

            <label htmlFor="college-attended">
              Name of College Attended <Red>*</Red>
            </label>
            <input
              type="text"
              id="college-attended"
              name="collegeAttended"
              required
            />
            <label htmlFor="loan-year">
              Year in School Loan Will Help Cover <Red>*</Red>
            </label>
            <input type="text" id="loan-year" name="loanYear" required />
            <label htmlFor="major-in-college">
              College Major (and Minor, if applicable) <Red>*</Red>
            </label>
            <input
              type="text"
              id="major-in-college"
              name="majorInCollege"
              required
            />
            <label htmlFor="year-enrolled">
              Year Enrolled <Red>*</Red>
            </label>
            <input
              type="text"
              id="year-enrolled"
              name="yearEnrolled"
              required
            />
            <label htmlFor="year-completed">
              Year Completed <Red>*</Red>
            </label>
            <input
              type="text"
              id="year-completed"
              name="yearCompleted"
              required
            />
            <label htmlFor="college-transcript">
              Transcript <Red>*</Red>
            </label>
            <input
              type="file"
              id="college-transcript"
              name="collegeTranscript"
              required
            />
            <Label>Parent Financial Obligation:</Label>
            <label htmlFor="loan-amount">
              College Loan Amount <Red>*</Red>
            </label>
            <input type="text" id="loan-amount" name="loanAmount" required />
            <label htmlFor="loan-agreement">
              Loan Agreement or Promissory Note <Red>*</Red>
            </label>
            <input
              type="file"
              id="loan-agreement"
              name="loanAgreement"
              required
            />

            <label htmlFor="loan-statement">
              Loan Servicer Statements <Red>*</Red>
            </label>
            <input
              type="file"
              id="loan-statement"
              name="loanStatement"
              required
            />

            <label htmlFor="application-letter">
              Application Letter (See the instructions above) <Red>*</Red>
            </label>
            <input
              type="file"
              id="application-letter"
              name="applicationLetter"
              required
            />

            <Label>Proof of Financial Need Info: </Label>
            <label htmlFor="current-expense">
              Current income and expense documentation <Red>*</Red>
            </label>
            <input
              type="file"
              id="current-expense"
              name="currentExpense"
              required
            />
            <label htmlFor="exployment-status">
              Employment status: Full-time, Part-time, or Laid-off <Red>*</Red>
            </label>
            <input
              type="text"
              id="exployment-status"
              name="exploymentStatus"
              required
            />

            <label htmlFor="gross-income">
              Gross Annual Income <Red>*</Red>
            </label>
            <input type="text" id="gross-income" name="grossIncome" required />

            <label htmlFor="loan-repayment-history">
              Loan repayment history record <Red>*</Red>
            </label>
            <input
              type="file"
              id="loan-repayment-history"
              name="loanRepaymentHistory"
              required
            />

            <label htmlFor="credit-report">
              Credit Report showing the status of the student loans, and how
              loan repayment is affecting credit score <Red>*</Red>
            </label>
            <input
              type="file"
              id="credit-report"
              name="creditReport"
              required
            />

            <label htmlFor="household-size">
              Household size <Red>*</Red>
            </label>
            <input
              type="text"
              id="household-size"
              name="householdSize"
              required
            />

            <label htmlFor="house-status">
              House Own / Rent <Red>*</Red>
            </label>
            <input type="text" id="house-status" name="houseStatus" required />

            <input type="submit" value="Submit" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default PageForParents;

import { useState, useEffect, useRef } from "react";
import "./homeHeroBanner.css";
import { gsap } from "gsap";

const HomeHero = () => {
  const divRef = useRef(null);
  const [width, setWidth] = useState(0);
  const [sectionHeight, setSectionHeight] = useState(0);
  const [ratio, setRatio] = useState(false);

  useEffect(() => {
    if (divRef.current) {
      var tempW = divRef.current.getBoundingClientRect().width;
      var tempH = divRef.current.getBoundingClientRect().height;
      setWidth(divRef.current.getBoundingClientRect().width);
      setSectionHeight(divRef.current.getBoundingClientRect().height);
      if (tempW / tempH <= 1.6) {
        setRatio(true);
      }
    }
  }, []);

  return (
    <div className="">
      <div className="hero-section">
        <div className="home-hero-overlay"></div>
        <div className="hero-image">
          <video className="hero-video" autoPlay muted loop>
            <source src="./video/home/video5.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="hero-heading">
          <div className="hero-header">
            <div className="get-width" ref={divRef}>
              Bent Christy <br />
              Graduate <br /> Assistance <br /> Program
            </div>
            <div>
              <a href="/donate-bent-christy-graduate-assistance-program">
                <button className="btn-donate">Donate</button>
              </a>
              <a href="/#bcgap-apply-for-repayment-and-scholarship">
                <button className="btn-apply">Apply</button>
              </a>
            </div>
          </div>
          <div className="hero-subheader">
            <div className="subheader-group">
              <span className="hero-sub-word">being </span>{" "}
              <span className="hero-sub-word">the change</span>{" "}
              <span className="hero-sub-word"> we need</span>{" "}
              <span className="hero-sub-word"> to see</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeHero;

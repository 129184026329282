import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./navigation.css";
import { ReactComponent as LogoSVG } from "./assets/logo-equal.svg";

const HomeNavigation = () => {
  const location = useLocation();
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
    setScreenHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handlwShowMobileMenu = () => {
    if (screenWidth < 1000) {
      setShowMobileMenu(!showMobileMenu);
    }
    if (screenWidth > 1020 && screenHeight > 1300) {
      setShowMobileMenu(!showMobileMenu);
    }
  };

  const showWelcome = () => {
    if (screenWidth > 1000) {
      switch (location.pathname) {
        case "/":
          return { display: "none" };
        default:
          return { display: "flex" };
      }
    } else {
      return { display: "flex" };
    }
  };

  const getNavBarStyle = () => {
    if (screenWidth > 1000) {
      switch (location.pathname) {
        case "/bent-christy-graduate-assistance-program-for-college-students":
          return { color: "white" };
        case "/bent-christy-graduate-assistance-program-for-high-school-students":
          return { color: "white" };
        case "/apply-for-repayment-bent-christy-graduate-assistance-program":
          return { color: "white" };
        case "/bent-christy-graduate-assistance-program-for-parents":
          return { color: "white" };
        default:
          return { color: "var(--site-nav-gray)" };
      }
    } else {
      return { color: "var(--site-nav-gray)" };
    }
  };

  const getNavBarStyleDropDownArrow = () => {
    if (screenWidth > 1000) {
      switch (location.pathname) {
        case "/bent-christy-graduate-assistance-program-for-college-students":
          return "ffffff";
        case "/bent-christy-graduate-assistance-program-for-high-school-students":
          return "ffffff";
        case "/apply-for-repayment-bent-christy-graduate-assistance-program":
          return "ffffff";
        case "/bent-christy-graduate-assistance-program-for-parents":
          return "ffffff";
        default:
          return "27313a";
      }
    } else {
      return "FFFFFF";
    }
  };

  const getNavBarStyleDonateBtn = () => {
    switch (location.pathname) {
      case "/bent-christy-graduate-assistance-program-for-college-students":
        return { backgroundColor: "var(--site-dark-gray)" };
      case "/bent-christy-graduate-assistance-program-for-high-school-students":
        return { backgroundColor: "var(--site-dark-gray)" };
      case "/about-bent-christy-graduate-assistance-program":
        return { backgroundColor: "var(--site-dark-gray)" };
      default:
        return { backgroundColor: "var(--site-dark-gray)" };
    }
  };

  const getNavBarStyleLogo = () => {
    switch (location.pathname) {
      case "/bent-christy-graduate-assistance-program-for-college-students":
        return "white";
      case "/bent-christy-graduate-assistance-program-for-high-school-students":
        return "white";
      case "/apply-for-repayment-bent-christy-graduate-assistance-program":
        return "white";
      case "/bent-christy-graduate-assistance-program-for-parents":
        return "white";
      case "/":
        return "white";
      case "/about-bent-christy-graduate-assistance-program":
        return "var(--site-dark-gray)";
      default:
        return "var(--site-dark-gray)";
    }
  };

  const getSandwichMenuColor = () => {
    switch (location.pathname) {
      case "/bent-christy-graduate-assistance-program-for-college-students":
        return "ffffff";
      case "/bent-christy-graduate-assistance-program-for-high-school-students":
        return "ffffff";
      case "/apply-for-repayment-bent-christy-graduate-assistance-program":
        return "ffffff";
      case "/bent-christy-graduate-assistance-program-for-parents":
        return "ffffff";
      case "/":
        return "ffffff";
      case "/about-bent-christy-graduate-assistance-program":
        return "27313a";
      default:
        return "27313a";
    }
  };

  const getNavBarStyleLogoStroke = () => {
    switch (location.pathname) {
      case "/bent-christy-graduate-assistance-program-for-college-students":
        return "white";
      case "/bent-christy-graduate-assistance-program-for-high-school-students":
        return "white";
      case "/apply-for-repayment-bent-christy-graduate-assistance-program":
        return "white";
      case "/about-bent-christy-graduate-assistance-program":
        return "var(--site-dark-gray)";
      case "/bent-christy-graduate-assistance-program-for-parents":
        return "white";
      case "/":
        return "red";
      default:
        return "var(--site-dark-gray)";
    }
  };

  const getNavImage = () => {
    switch (location.pathname) {
      case "/bent-christy-graduate-assistance-program-for-college-students":
        return "orange-nav-3.svg";
      case "/bent-christy-graduate-assistance-program-for-high-school-students":
        return "blue-nav-15.svg";
      case "/bent-christy-graduate-assistance-program-for-parents":
        return "red-nav.svg";
      default:
        return "";
    }
  };

  const getNavImageDisplay = () => {
    switch (location.pathname) {
      case "/bent-christy-graduate-assistance-program-for-college-students":
        return "flex";
      case "/bent-christy-graduate-assistance-program-for-high-school-students":
        return "flex";
      case "/bent-christy-graduate-assistance-program-for-parents":
        return "flex";
      default:
        return "none";
    }
  };

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--site-logo-color",
      getNavBarStyleLogo()
    );
    document.documentElement.style.setProperty(
      "--site-logo-stroke",
      getNavBarStyleLogoStroke()
    );
  }, [location.pathname]);

  return (
    <>
      <div className="nav-image" style={{ display: getNavImageDisplay() }}>
        <img
          src={`./images/navigation/${getNavImage()}`}
          alt="bcgap-navigation-image"
        />
      </div>

      <div className="navigation-bar" style={getNavBarStyle()}>
        <div className="logo">
          <a href="/" style={getNavBarStyle()}>
            <LogoSVG className="logo-icon-svg" />
          </a>
        </div>

        <div className="all-nav-links">
          <div className="nav-wrapper">
            <div className="nav-tab" style={showWelcome()}>
              <a href="/" style={getNavBarStyle()}>
                Welcome
              </a>
            </div>
          </div>
          <div className="nav-wrapper">
            <div className="nav-tab">
              About Us
              <span className="nav-arrow-expand">
                <img
                  src={`https://img.icons8.com/fluency-systems-filled/${getNavBarStyleDropDownArrow()}/96/expand-arrow.png`}
                  alt="expand-arrow"
                />
              </span>
            </div>
            <div className="dropdown-menu">
              <div className="dropdown-item">
                <a href="/about-bent-christy-graduate-assistance-program">
                  Who We Are
                </a>
              </div>
              <div className="dropdown-item">
                <a href="/bent-christy-graduate-assistance-program-our-team">
                  Our Team
                </a>
              </div>
              <div className="dropdown-item">
                <a href="/bent-christy-graduate-assistance-program-contact-us">
                  Contact Us
                </a>
              </div>
            </div>
          </div>
          <div className="nav-wrapper">
            <div className="nav-tab">
              Repayment & Scholarship
              <span className="nav-arrow-expand">
                <img
                  src={`https://img.icons8.com/fluency-systems-filled/${getNavBarStyleDropDownArrow()}/96/expand-arrow.png`}
                  alt="expand-arrow"
                />
              </span>
            </div>
            <div className="dropdown-menu">
              <div className="dropdown-item">
                <a href="/bent-christy-graduate-assistance-program-for-college-students">
                  BCGAP Undergraduate Repayment
                </a>
              </div>
              <div className="dropdown-item">
                <a href="/bent-christy-graduate-assistance-program-for-parents">
                  BCGAP Parent Repayment
                </a>
              </div>
              <div className="dropdown-item">
                <a href="/bent-christy-graduate-assistance-program-for-high-school-students">
                  BCGAP High School Senior Scholarship
                </a>
              </div>
            </div>
          </div>
          <div className="nav-wrapper">
            <div className="nav-tab full">
              <a
                href="/bent-christy-graduate-assistance-program-news-stories"
                style={getNavBarStyle()}
              >
                News & Stories
              </a>
            </div>
          </div>
          <div className="nav-wrapper">
            <div className="nav-tab">
              <a style={getNavBarStyle()}>Get Involved</a>
              <span className="nav-arrow-expand">
                <img
                  src={`https://img.icons8.com/fluency-systems-filled/${getNavBarStyleDropDownArrow()}/96/expand-arrow.png`}
                  alt="expand-arrow"
                />
              </span>
            </div>
            <div className="dropdown-menu">
              <div className="dropdown-item">
                <a href="/bent-christy-graduate-assistance-program-become-a-partner">
                  Become a Partner
                </a>
              </div>
              <div className="dropdown-item">
                <a href="/bent-christy-graduate-assistance-program-volunteer-opportunities">
                  Volunteer
                </a>
              </div>
              <div className="dropdown-item">
                <a href="/bent-christy-graduate-assistance-program-bcgap-store">
                  Shop BCGAP{" "}
                </a>
              </div>
              <div className="dropdown-item">
                <a href="/donate-bent-christy-graduate-assistance-program">
                  Donate
                </a>
              </div>
            </div>
          </div>
          <div className="nav-wrapper">
            <div className="nav-tab">
              {" "}
              <a href="/donate-bent-christy-graduate-assistance-program">
                <button style={getNavBarStyleDonateBtn()}>DONATE</button>
              </a>
            </div>
          </div>
        </div>

        <div
          className="all-nav-links-mobile"
          style={{
            display: showMobileMenu ? "flex" : "none",
          }}
        >
          <div className="nav-wrapper">
            <div className="nav-tab">
              <a href="/">Welcome</a>
            </div>
            <div className="nav-tab">
              <a href="">About Us</a>
            </div>
            <div className="dropdown-menu">
              <div className="dropdown-item">
                <a href="/about-bent-christy-graduate-assistance-program">
                  Who We Are
                </a>
              </div>
              <div className="dropdown-item">
                <a href="/bent-christy-graduate-assistance-program-our-team">
                  Our Team
                </a>
              </div>
            </div>
          </div>
          <div className="nav-wrapper">
            <div className="nav-tab">
              <a href="">Repayment & Scholarship</a>
            </div>
            <div className="dropdown-menu">
              <div className="dropdown-item">
                <a href="/bent-christy-graduate-assistance-program-for-college-students">
                  BCGAP Undergraduate Repayment
                </a>
              </div>
              <div className="dropdown-item">
                <a href="/bent-christy-graduate-assistance-program-for-parents">
                  BCGAP Parent Repayment
                </a>
              </div>
              <div className="dropdown-item">
                <a href="/bent-christy-graduate-assistance-program-for-high-school-students">
                  BCGAP High School Senior Scholarship
                </a>
              </div>
            </div>
          </div>
          <div className="nav-wrapper">
            <div className="nav-tab">
              <a href="/bent-christy-graduate-assistance-program-news-stories">
                News & Stories
              </a>
            </div>
          </div>
          <div className="nav-wrapper">
            <div className="nav-tab">
              <a href="">Get Involved</a>
            </div>
            <div className="dropdown-menu">
              <div className="dropdown-item">
                <a href="/bent-christy-graduate-assistance-program-become-a-partner">
                  Become a Partner
                </a>
              </div>
              <div className="dropdown-item">
                <a href="/bent-christy-graduate-assistance-program-volunteer-opportunities">
                  Volunteer
                </a>
              </div>
              <div className="dropdown-item">
                <a href="/bent-christy-graduate-assistance-program-bcgap-store">
                  Shop BCGAP{" "}
                </a>
              </div>
              <div className="dropdown-item">
                <a href="/donate-bent-christy-graduate-assistance-program">
                  Donate
                </a>
              </div>
              <div className="dropdown-item">
                <a href="/bent-christy-graduate-assistance-program-contact-us">
                  Contact Us
                </a>
              </div>
            </div>
          </div>
          <div className="nav-wrapper" style={{ marginTop: "1em" }}>
            <div className="nav-tab">
              {" "}
              <a href="/donate-bent-christy-graduate-assistance-program">
                <button>DONATE</button>
              </a>
            </div>
          </div>
        </div>
        <div className="sandwich-menu" onClick={handlwShowMobileMenu}>
          {showMobileMenu ? (
            <img
              src={`https://img.icons8.com/ios/${getSandwichMenuColor()}/100/delete-sign--v1.png`}
              alt="close-mobile-menu"
            />
          ) : (
            <img
              src={`https://img.icons8.com/ios/${getSandwichMenuColor()}/100/menu--v1.png`}
              alt="open-mobile-menu"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default HomeNavigation;

{
  /*
    useEffect(() => {
      gsap.to('.box', {
        y: 100,
        stagger: 0.1 // 0.1 seconds between when each ".box" element starts animating
    });
    }, [])
*/
}

import "./PageForHighSchoolStudents-2.css";
import Bold from "./styleBold";
import Red from "./styleRed";
import { useState, useRef } from "react";

const PageForHighSchoolStudents = () => {
  const applicationAboutRef = useRef(null);
  const applicationDeadlinesRef = useRef(null);
  const applicationFormRef = useRef(null);
  const [showContent, setShowContent] = useState([false, false, false]);

  const handleShowContent = (index) => {
    setShowContent((prevShowContent) =>
      prevShowContent.map((item, i) => (i === index ? !item : item))
    );
  };

  return (
    <div className="high-school-page funding-page">
      <div className="funding-heading">
        Bent Christy Graduate Assistance Program (BCGAP) Foundation Scholarship
        Program
      </div>
      <div className="funding-content">
        <div className="text-image">
          <div className="image">
            <img
              src="./images/for-students/h-student2.jpg"
              alt="high-school-student-photo"
            />
          </div>
          <div className="text">
            <div className="paragraph">
              Great job on reaching the exciting milestone of high school
              graduation!
            </div>
            <div className="paragraph">
              As you begin the next chapter of your education, we want to inform
              you how{" "}
              <Bold>Bent Christy Graduate Assistance Program (BCGAP)</Bold> can
              assist with college loan repayment. We recognize that managing
              student loan repayments after graduation can be stressful. BCGAP
              believes that financial circumstances should never be a barrier to
              pursuing a college education. That is why we offer financial
              assistance to qualified graduates who completed their degrees
              within four years and are struggling with loan repayment. Here is
              how we can help as you embark on the next phase of your
              educational journey.
            </div>{" "}
            <div className="paragraph">
              Our work is fueled by a passion for education, and being the
              change we need to see to end the college debt crisis. Learn more
              about our mission, our vision, and the opportunity how you can
              empower and help lighten the burden of college debt for you and
              the next generation.
            </div>
          </div>
        </div>
        <div
          style={{ marginTop: "2rem" }}
          className="subheading"
          ref={applicationAboutRef}
          onClick={() => handleShowContent(0)}
        >
          About the Scholarship{" "}
          <img
            style={{
              transform: showContent[0] ? "rotate(180deg)" : "rotate(0deg)",
            }}
            src="https://img.icons8.com/ios-filled/00c8dc/100/expand-arrow--v1.png"
            alt="show-about-scholarship"
          />
        </div>
        <div
          className="application-content"
          style={{ display: showContent[0] ? "block" : "none" }}
        >
          <div>
            The Bent Christy Graduate Assistance Program (BCGAP) Foundation
            Scholarship Program offers academic achievement-based scholarships
            to graduating high school seniors. Scholarship recipients are
            selected based on their leadership, service, and the potential
            impact they offer in their schools and the local community.
          </div>
          <div style={{ marginTop: "1.5rem" }}>
            <Bold>Eligibility</Bold> <br />
            The $1,000 BCGAP Scholarship begins at 12:00 AM Eastern Time (ET)
            Monday, May 01, 2024 and ends at 12:00 PM ET on Friday, August 01,
            2024. The scholarship is opened to U.S. Citizen, Nationals and
            permanent residents and who meet the following requirement
          </div>
          <div style={{ marginTop: "1.5rem" }}>
            <Bold>Requirements</Bold>
            <ul>
              <li>Minimum 2.50 GPA</li>
              <li> High school senior</li>
              <li>Seeking a bachelor's degree</li>
              <li>Plan to attend school in the U.S.</li>
              <li>U.S. Resident </li>
              <li>U.S. citizen, nationals, permanent residents</li>
              <li>
                Demonstrate leadership through participation in community
                service and extracurricular activities
              </li>
              <li>Demonstrate financial need</li>
              <li>Proven academic achievement</li>
            </ul>
          </div>

          <div style={{ marginTop: "1.5rem" }}>
            <Bold>Required Documents</Bold>
            <ul>
              <li>Transcript</li>
              <li>Letters of recommendation</li>
              <li>Proof of acceptance to college</li>
              <li>Essays</li>
            </ul>
          </div>
        </div>
        <div
          className="subheading"
          ref={applicationDeadlinesRef}
          onClick={() => handleShowContent(1)}
        >
          Application Deadlines & Winners
          <img
            style={{
              transform: showContent[1] ? "rotate(180deg)" : "rotate(0deg)",
            }}
            src="https://img.icons8.com/ios-filled/00c8dc/100/expand-arrow--v1.png"
            alt="show-application-deadline"
          />
        </div>
        <div
          className="application-content"
          style={{ display: showContent[1] ? "block" : "none" }}
        >
          <div style={{ marginTop: "1rem" }}>
            <Bold>Next Deadline:</Bold> August 1, 2024
          </div>
          <div style={{ marginTop: "1.5rem" }}>
            <Bold>Upcoming Deadlines</Bold> <br />
            September 1, 2024 <br /> October 1, 2024 <br />
            Novermber 1, 2024 <br /> December 1, 2024
          </div>
          <div style={{ marginTop: "1.5rem" }}>
            <Bold>Winners</Bold> <br />
            July 1, 2024 - Jane Doe <br />
            June 1, 2024 - Jane Doe <br />
            May 1, 2024 - Jane Doe
          </div>
        </div>
        <div
          className="subheading"
          ref={applicationFormRef}
          onClick={() => handleShowContent(2)}
        >
          Application Form
          <img
            style={{
              transform: showContent[2] ? "rotate(180deg)" : "rotate(0deg)",
            }}
            src="https://img.icons8.com/ios-filled/00c8dc/100/expand-arrow--v1.png"
            alt="show-application-form"
          />
        </div>
        <div
          className="application-content"
          style={{ display: showContent[2] ? "block" : "none" }}
        >
          <div>
            Please fill out the form below to submit your scholarship
            application.
          </div>
          <form>
            <label htmlFor="first-name">
              First Name <Red>*</Red>
            </label>
            <input type="text" id="first-name" name="firstName" required />
            <label htmlFor="last-name">
              Last Name <Red>*</Red>
            </label>
            <input type="text" id="last-name" name="lastName" required />
            <label htmlFor="email">
              Email <Red>*</Red>
            </label>
            <input type="email" id="email" name="email" required />
            <label htmlFor="address">
              Address <Red>*</Red>
            </label>
            <input type="address" id="address" name="address" required />
            <label htmlFor="high-school-attended">
              High School Attended <Red>*</Red>
            </label>
            <input
              type="text"
              id="high-school-attended"
              name="highSchoolAttended"
              required
            />
            <label htmlFor="another-field">
              Year of Graduation <Red>*</Red>
            </label>
            <input
              type="text"
              id="year-of-graduation"
              name="yearOfGraduation"
              required
            />
            <label htmlFor="citizenship">
              Citizen Type <Red>*</Red>
            </label>
            <input type="text" id="citizenship" name="citizenship" required />
            <label htmlFor="college-accepted">
              College Accepted <Red>*</Red>
            </label>
            <input
              type="text"
              id="college-accepted"
              name="collegeAccepted"
              required
            />
            <label htmlFor="application-letter">
              Academic Records <Red>*</Red>
            </label>
            <input
              type="file"
              id="academic-records"
              name="academicRecords"
              required
            />
            <label htmlFor="application-essay">
              Application Essay <Red>*</Red>
            </label>
            <input
              type="file"
              id="application-essay"
              name="applicationEssay"
              required
            />
            <label htmlFor="recommendation-letters">
              Letters of Recommendation <Red>*</Red>
            </label>
            <input
              type="file"
              id="recommendation-letters"
              name="recommendationLetters"
              required
            />
            <label htmlFor="comments">Comments</label>
            <textarea type="text" id="comments" name="comments" />
            <input type="submit" value="Submit" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default PageForHighSchoolStudents;

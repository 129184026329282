const bcgapStoreItems = [
  {
    id: "1",
    image: "./store-images/white-tshirt.jpg",
    title: "BCGAP T-Shirt",
    description: "This is a t-shirt.",
    price: "10",
  },
  {
    id: "2",
    image: "./store-images/white-f-tshirt.jpg",
    title: "BCGAP T-Shirt",
    description: "This is another t-shirt.",
    price: "8",
  },
  {
    id: "3",
    image: "./store-images/yellow-tshirt.jpg",
    title: "BCGAP T-Shirt",
    description: "This is one more amazing t-shirt.",
    price: "12",
  },
];

export default bcgapStoreItems;

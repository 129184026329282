import "./bcgapStore.css";
import { useState, useEffect } from "react";
import bcgapStoreItems from "./bcgapStoreItems";
import StoreTop from "./storeTop";

const BcgapStore = () => {
  const [countCart, setCountCart] = useState(0);
  const [itemQuantities, setItemQuantities] = useState(
    bcgapStoreItems.map(() => 1)
  );
  const [cart, setCart] = useState([]);

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);

  const handleAddToCart = (e, item, index) => {
    e.preventDefault();
    const newOrder = {
      item: item,
      qty: itemQuantities[index],
    };
    setCart((prevCart) => [...prevCart, newOrder]);

    setCountCart((prevCountCart) => prevCountCart + itemQuantities[index]);

    const newQuantities = [...itemQuantities];
    newQuantities[index] = 1;
    setItemQuantities(newQuantities);
  };

  const handleItemQuantity = (e, index) => {
    const newQuantities = [...itemQuantities];
    newQuantities[index] = parseInt(e.target.value, 10);
    setItemQuantities(newQuantities);
  };

  const shopMugs = [
    { name: "BCGAP Mug", price: "10.00", image: "./store-images/mug3.png" },
    { name: "BCGAP Mug", price: "12.00", image: "./store-images/mug1.png" },
    { name: "BCGAP Mug", price: "12.00", image: "./store-images/mug2.png" },
  ];

  const shopShirts = [
    {
      name: "BCGAP T-Shirt",
      price: "25.00",
      image: "./store-images/shirt10.png",
    },
    {
      name: "BCGAP T-Shirt",
      price: "25.00",
      image: "./store-images/shirt11.png",
    },
    {
      name: "BCGAP T-Shirt",
      price: "25.00",
      image: "./store-images/shirt12.png",
    },
  ];

  const shopBags = [
    {
      name: "BCGAP Tote Bag",
      price: "25.00",
      image: "./store-images/bag1.png",
    },
    {
      name: "BCGAP Tote Bag",
      price: "13.00",
      image: "./store-images/bag2.png",
    },
    {
      name: "BCGAP Tote Bag",
      price: "13.00",
      image: "./store-images/bag3.png",
    },
  ];

  return (
    <div className="store-section">
      <div className="header"> Shop BCGAP to end college debt!</div>
      <div className="shop-tabs">
        <div className="shop-tab-header">CATEGORIES</div>
        <div className="shop-tab">Mugs</div>
        <div className="shop-tab">Shirts</div>
        <div className="shop-tab">Bags</div>
        <div className="shop-tab">Pens</div>
        <div className="shop-tab">Stickers</div>
      </div>
      <div className="store">
        <div className="products">
          {shopMugs.map((mug) => (
            <div className="store-item-card">
              <div className="store-item-image">
                <img src={`${mug.image}`} alt="card-item-image" />
              </div>
              <div className="store-item-description">
                <div className="store-name-price">
                  <div className="store-item-title">{mug.name}</div>
                  <div className="store-item-price">
                    $ {mug.price} + shipping
                  </div>
                </div>
                <a href="" className="buy-btn">
                  <img
                    src="https://img.icons8.com/sf-regular/96/buy.png"
                    alt="buy"
                  />
                </a>
              </div>
            </div>
          ))}
        </div>
        <div className="products">
          {shopShirts.map((shirt) => (
            <div className="store-item-card">
              <div className="store-item-image">
                <img src={`${shirt.image}`} alt="card-item-image" />
              </div>
              <div className="store-item-description">
                <div className="store-name-price">
                  <div className="store-item-title">{shirt.name}</div>
                  <div className="store-item-price">
                    $ {shirt.price} + shipping
                  </div>
                </div>
                <a href="" className="buy-btn">
                  <img
                    src="https://img.icons8.com/sf-regular/96/buy.png"
                    alt="buy"
                  />
                </a>
              </div>
            </div>
          ))}
        </div>
        <div className="products">
          {shopBags.map((bag) => (
            <div className="store-item-card">
              <div className="store-item-image">
                <img src={`${bag.image}`} alt="card-item-image" />
              </div>
              <div className="store-item-description">
                <div className="store-name-price">
                  <div className="store-item-title">{bag.name}</div>
                  <div className="store-item-price">
                    $ {bag.price} + shipping
                  </div>
                </div>
                <a href="" className="buy-btn">
                  <img
                    src="https://img.icons8.com/sf-regular/96/buy.png"
                    alt="buy"
                  />
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BcgapStore;

import { useEffect, useState } from "react";
import "./PageTeam.css";
import Typewriter from "./typewriterEffect";

const PageTeam = () => {
  const [photoVisible, setPhotoVisible] = useState(false);

  return (
    <div className="team-page">
      <div className="team-header">
        <Typewriter text="being the change we need to see" />
      </div>
      <div className="team-container">
        <div className="our-team-block">
          <div className="photo-block">
            <div className="team-photo">
              <img src="./images/home/headshot1.webp" alt="" />
              <div className="photo-caption">
                <div className="photo-caption-name">Jane Doe</div>
                <div className="photo-caption-role">Founder</div>
              </div>
            </div>
            <div className="photo-blurb">
              Proin tempor risus justo, at accumsan velit vestibulum eget.
              Vivamus auctor nisi in nunc ullamcorper, non vestibulum erat
              efficitur. Donec rutrum purus ut dolor ultricies vestibulum. Cras
              ac dapibus ex. Nulla facilisi. Donec sit amet ante in nisl
              efficitur ultrices vitae ac ipsum. Fusce vehicula, felis ac
              suscipit eleifend, risus odio dignissim risus, id ultrices libero
              lorem id nisl.{" "}
            </div>
          </div>
          <div className="photo-block">
            <div className="team-photo">
              <img src="./images/home/headshot2.webp" alt="" />
              <div className="photo-caption">
                <div className="photo-caption-name">Mary Doe</div>
                <div className="photo-caption-role">Board Secretary</div>
              </div>
            </div>
            <div className="photo-blurb">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
              facilisi. Duis varius eleifend tortor, in imperdiet orci ultricies
              a. Pellentesque ac turpis a nulla accumsan luctus. Sed sit amet
              tortor quis enim commodo dictum. Morbi at massa vel nulla
              venenatis tincidunt. Ut id mauris vel magna accumsan fermentum
              eget in sem. Fusce ultricies justo sit amet lectus scelerisque,
              sed blandit sem cursus. Nam ultrices tincidunt tincidunt.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageTeam;
